<template>
     <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="dots"></loader>
</template>
<style>

</style>
<script>
export default {
props: {
    cor: String
},
data: () => ({
dialog: true,

}),
};
</script>